<div class="img">
    <img src="/assets/img/csapat.jpg" alt="Pannon Set Kft. csapata">
</div>
<div class="container">
    <h1>{{"header.cegunkrol" | translate}}</h1>
    <p>
        {{"header.cegunkrol-text.one" | translate}}
    </p>
    <p>
        {{"header.cegunkrol-text.two" | translate}}
    </p>
</div>
<div class="fullcont">
    <div class="container">
        <a class="more" href="https://www.ps.hu" target="_blank">{{"cegunkrol.tovabbi" | translate}}</a>
    </div>
</div>
