import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-bevezeto',
  templateUrl: './bevezeto.component.html',
  styleUrls: ['./bevezeto.component.css']
})
export class BevezetoComponent implements OnInit {

  constructor(private cookieService: CookieService) { }
  languagehu = false;

  getCookie(name) {
    return this.cookieService.get(name);
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }
  

  ngOnInit() {
  }

}
