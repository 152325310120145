import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Title } from '@angular/platform-browser';
export interface ModalModel {
  title?: string;
  message?: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent extends SimpleModalComponent<ModalModel, boolean> implements ModalModel {
  title?: string;
  message?: string;
  constructor() {
    super();
  }
  confirm() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    this.result = true;
    this.close();
  }

}
