<body>
    <div>
        <h2 class="background double" style="text-align: center; padding-bottom: 30px;">
            <strong>{{"yellow-dot.menu-six-title" | translate}}</strong>
        </h2>

        <div class="pad">
            <!-- <p style="text-align: center;"><strong>A Netfaktor működése</strong></p> -->
            <ul class="margin-ul">
                <li>{{"kkr.li.one" | translate}}</li>
                <li>{{"kkr.li.two" | translate}}</li>
            </ul>
            <ul class="margin-ul">
                <li>{{"kkr.li.three" | translate}}</li>
                <li>{{"kkr.li.four" | translate}}</li>
            </ul>
            <ul class="margin-ul">
                <li>{{"kkr.li.five" | translate}}</li>
                <li>{{"kkr.li.six" | translate}}</li>
            </ul>
            <ul class="margin-ul" style="padding-bottom: 50px;">
                <li>{{"kkr.li.seven" | translate}}</li>
                <li>{{"kkr.li.eight" | translate}}</li>
                <li>{{"kkr.li.nine" | translate}}</li>
            </ul>
        </div>
        <div style="position: relative; text-align: right; padding-right: 200px; padding-bottom: 50px;">
            <button _ngcontent-serverapp-c13="" class="arrow-bttn"><a style=" text-decoration: none;"
                    onclick="history.back()"><span _ngcontent-serverapp-c13=""
                        style="font-size: 30px; text-transform:none; font-weight: 100; font-family: sans-serif !important;">&lt;&lt;
                        {{"home" | translate}}</span></a></button>
        </div>
    </div>

</body>