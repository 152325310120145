import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalComponent } from '../modal/modal.component';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-bullets-masik',
  templateUrl: './bulletsmasik.component.html',
  styleUrls: ['./bulletsmasik.component.css']
})
export class BulletsMasikComponent implements OnInit {
  constructor(private simpleModalService: SimpleModalService, private util: AppComponent, private cookieService: CookieService) { }
  languagehu = false;

  options = [
    {
      "icon": "/assets/img/house.png",
      "title": "yellow-dot.menu-one-title",
      "link":"/ingatlan",
      "description": "ingatlan.description",
      "points": [
        "ingatlan.adatok",
        "ingatlan.two",
        "ingatlan.three"
      ]
    },
    {
      "icon": "/assets/img/wrench.png",
      "title": "yellow-dot.menu-two-title",
      "link":"/muszak",
      "description": "muszak.description",
      "points": [
        "muszak.elektronikus_munkalap.base",
        "muszak.elektronikus_munkalap.one",
        "muszak.elektronikus_munkalap.two",
        "muszak.elektronikus_munkalap.three",
      ]
    },
    {
      "icon": "/assets/img/paper.png",
      "title": "yellow-dot.menu-three-title",
      "link":"/szamla",
      "description": "szamla.description",
      "points": [
        "szamla.lehetosegek.base",
        "szamla.lehetosegek.one",
        "szamla.lehetosegek.two"
      ]
    },
    {
      "icon": "/assets/img/cash.png",
      "title": "yellow-dot.menu-four-title",
      "link":"/likvid",
      "description": "likvid.description",
      "points": [
        "likvid.lehetosegek.base",
        "likvid.lehetosegek.one",
        "likvid.lehetosegek.two",
        "likvid.lehetosegek.three",
      ]
    },
    {
      "icon": "/assets/img/reciept.png",
      "title": "yellow-dot.menu-five-title",
      "link":"/irat",
      "description": "irat.description",
      "points": [
        "irat.lehetosegek.base",
        "irat.lehetosegek.one",
        "irat.lehetosegek.two",
        "irat.lehetosegek.three"
      ]
    },
    {
      "icon": "/assets/img/wrench.png",
      "title": "yellow-dot.menu-six-title",
      "link":"/kkr",
      "description": "kkr.description",
      "points": [     
        "kkr.automatizalja.base",
        "kkr.automatizalja.one",
        "kkr.automatizalja.two",
        "kkr.automatizalja.three"
      ]
    },
    {
      "icon": "/assets/img/accountant.png",
      "title": "yellow-dot.menu-seven-title",
      "link":"/ugyfelkapu",
      "description": "ugyfelkapu.description",
      "points": [
        "ugyfelkapu.ugyfel.base",
        "ugyfelkapu.ugyfel.one",
        "ugyfelkapu.ugyfel.two",
        "ugyfelkapu.ugyfel.three",
        "ugyfelkapu.ugyfel.four"   
      ]
    },
    {
      "icon": "/assets/img/riport.png",
      "title": "yellow-dot.menu-eight-title",
      "link":"/riport",
      "description": "riport.description",
      "points": [
        "riport.adatgyujtes.base",
        "riport.adatgyujtes.one",
        "riport.adatgyujtes.two",
        "riport.adatgyujtes.three"
      ]
    }
        
  ];

  getCookie(name) {
    return this.cookieService.get(name);
  }

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      {title: 'Ingyenes bemutató kérése'},
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true
      }
    );
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }
  

  ngOnInit() {
  }
}
