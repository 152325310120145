<div *ngIf="languagemenu()" class="slider">
    <div class="swiper-container" [swiper]="swiperconfig">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="text">
                    <h2>Ingatlan nyilvántartás</h2>
                    <h3>Lakás és nem lakás célú ingatlanok kezelése. Minden lényeges esemény, dokumentum egy helyen,
                        bérleti szerződések, alaprajzok, térképek, fényképek, dokumentumok</h3>
                        <button (click)="openModal()" class="kapcsbtn">Kapcsolat</button>
                </div>
                <div class="greet-image">
                    <img alt="property_folder" height="auto" width="auto"
                        src="assets/img/sliderimages/undraw_property_folder.svg">
                </div>
            </div>
            <div class="swiper-slide">
                <div class="text">
                    <h2>Ingatlan karbantartás</h2>
                    <h3>Elektronikus munkalap karbantartáshoz, műszaki feladatokhoz. Átadás-átvételi jegyzőkönyvek
                        készítése</h3>
                        <button (click)="openModal()" class="kapcsbtn">Kapcsolat</button>
                </div>
                <div class="greet-image">
                    <img alt="building" height="auto" width="auto" src="assets/img/building1.svg">
                </div>
            </div>
            <div class="swiper-slide">
                <div class="text">
                    <h2>Ügyfélkapu</h2>
                    <h3>Önkormányzatok számára, amelyeknek hatékonyan kell kezelniük ingatlanvagyonukat;</h3>
                        <button (click)="openModal()" class="kapcsbtn">Kapcsolat</button>
                </div>
                <div class="greet-image">
                    <img alt="client_gateway" height="auto" width="auto"
                        src="assets/img/sliderimages/undraw_clientgateway.svg">
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
</div>

<!-- angol -->

<div *ngIf="!languagemenu()" class="slider">
    <div class="swiper-container" [swiper]="swiperconfig">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="text">
                    <h2>Real Estate Registry</h2>
                    <h3>Management of residential and non-residential properties. All important events and documents in
                        one place, rental contracts, floor plans, maps, photographs, documents</h3>
                        <button (click)="openModal()" class="kapcsbtn">Contact</button>
                </div>
                <div class="greet-image">
                    <img alt="property_folder" height="auto" width="auto"
                        src="assets/img/sliderimages/undraw_property_folder.svg">
                </div>
            </div>
            <div class="swiper-slide">
                <div class="text">
                    <h2>Property maintenance</h2>
                    <h3>Electronic worksheet for maintenance and technical tasks. Preparation of handover and acceptance
                        protocols</h3>
                        <button (click)="openModal()" class="kapcsbtn">Contact</button>
                </div>
                <div class="greet-image">
                    <img alt="building" height="auto" width="auto" src="assets/img/building1.svg">
                </div>
            </div>
            <div class="swiper-slide">
                <div class="text">
                    <h2>Customer gateway</h2>
                    <h3>Electronic worksheet for maintenance and technical tasks. Preparation of handover and acceptance
                        protocols</h3>
                        <button (click)="openModal()" class="kapcsbtn">Contact</button>
                </div>
                <div class="greet-image">
                    <img alt="client_gateway" height="auto" width="auto"
                        src="assets/img/sliderimages/undraw_clientgateway.svg">
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
</div>