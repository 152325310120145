<footer>
  <div class="footer-colcont">
    <div class="col kapcs">
      <div class="title">
        {{"footer.ceginfo" | translate}}
      </div>
      <p><a class="ps" href="https://www.ps.hu">Pannon Set Kft.</a> 1999 - <span id="datetime"></span></p>
      <p>{{"footer.cim" | translate}}: <a href="https://goo.gl/maps/wUxALcM2CLQ96mJs8" target="_blank"
          rel="noreferrer">1117 Fehérvári u.
          24. IV./1</a></p>
      <p>{{"footer.telefon" | translate}}: <a class="adsconvfire" href="tel:+3617890575">+36 1/789-0575</a></p>
      <p>E-mail: <a class="adsconvfire" href="mailto:ps@ps.hu">ps@ps.hu</a></p>
      <div class="title"><a class="" href="https://www.ps.hu/" style="color: #fd8d3f">{{"footer.rolunk"
          |
          translate}}</a></div>

    </div>
    <div class="col foomenu">
      <div class="title">
        {{"footer.megoldasaink" | translate}}:
      </div>
      <div class="innercol">
        <ul>
          <li><a href="https://www.ps.hu/megoldasaink?active=erp">ERP</a></li>
          <li><a href="https://www.crm.ps.hu">CRM</a></li>
          <li><a href="http://dms.ps.hu">{{"footer.eiratkezeles" | translate}}</a></li>
          <li><a href="https://www.ps.hu/megoldasaink?workflow">WorkFlow</a></li>
          <li><a href="https://gyartas.ps.hu">{{"footer.gyartasterveztes" | translate}}</a></li>
        </ul>
      </div>
      <div class="innercol">
        <ul>
          <li><a href="https://www.ps.hu/megoldasaink?active=webshop">Webshop</a></li>
          <li><a href="http://www.koveteleskezeles.ps.hu">{{"footer.koveteles" | translate}}</a></li>
          <li><a href="https://www.ps.hu/megoldasaink?belepteto-rendszer">{{"footer.belepteto" | translate}}</a></li>
          <li><a href="https://www.proexchange.hu/">Netfaktor</a></li>
        </ul>
      </div>
    </div>
    <div class="col foomenu">
      <div class="title">
        {{"footer.dokumentumok" | translate}}
      </div>
      <div class="innercol">
        <ul>
          <li><a href="../../../assets/docs/SütikAlkalmazásárólSzólóTájékoztató.pdf"
              download="Süti tájékoztató">{{"footer.sutik" | translate}}</a></li>
          <li><a href="../../../assets/docs/SütikAlkalmazásárólSzólóTájékoztató.pdf"
              download="Adatkezelési tájékoztató">{{"footer.adatkezeles" | translate}}</a></li>
          <li><a href="../../../assets/docs/SütikAlkalmazásárólSzólóTájékoztató.pdf"
              download="Jogi nyilatkozat">{{"footer.joginyilatkozat" | translate}}</a></li>
          <li><a href="../../../assets/docs/SütikAlkalmazásárólSzólóTájékoztató.pdf" download="ÁSZF">{{"footer.aszf" |
              translate}}</a></li>
          <li><a href="../../../assets/docs/impresszum.pdf"
              download="Impresszum">{{"footer.impresszum" | translate}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>