import { Component, OnInit } from '@angular/core';
import { PlbrowserService } from '../../services/plbrowser.service';

declare const Rellax: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private plbrowserService: PlbrowserService) { }

  ngOnInit() {
    if (this.plbrowserService.isBrowser) {
      const rellax = new Rellax('.rellax');
    }
  }

}
