import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";

// Custom extras
import { SwiperModule } from "ngx-swiper-wrapper";
import { InViewportModule } from "@thisissoon/angular-inviewport";
import { NgxPageScrollModule } from "ngx-page-scroll";
import { AgmCoreModule } from "@agm/core";
import { SimpleModalModule } from "ngx-simple-modal";
import { NgxJsonLdModule } from "ngx-json-ld";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from 'ngx-cookie-service';

// Components
import { HomeComponent } from "./views/home/home.component";
import { PageNotFoundComponent } from "./views/page-not-found/page-not-found.component";
import { KapcsolatComponent } from "./views/kapcsolat/kapcsolat.component";
import { NavmenuComponent } from "./components/navmenu/navmenu.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { CegunkrolComponent } from "./views/cegunkrol/cegunkrol.component";
import { SliderComponent } from "./components/slider/slider.component";
import { BubblesComponent } from "./components/bubbles/bubbles.component";
import { BulletsComponent } from "./components/bullets/bullets.component";
import { ScrolltopComponent } from "./components/scrolltop/scrolltop.component";
import { TestimonialComponent } from "./components/testimonial/testimonial.component";
import { ReferenciakComponent } from "./components/referenciak/referenciak.component";
import { KapcsolaturlapComponent } from "./components/kapcsolaturlap/kapcsolaturlap.component";
import { MapsComponent } from "./components/maps/maps.component";
import { ModalComponent } from "./components/modal/modal.component";
import { AdatvedelemComponent } from "./views/adatvedelem/adatvedelem.component";
import { BevezetoComponent } from "./components/bevezeto/bevezeto.component";
import { TamogatasComponent } from "./views/tamogatas/tamogatas.component";
import { TamogatasurlapComponent } from "./components/tamogatasurlap/tamogatasurlap.component";
import { BulletsMasikComponent } from "./components/bulletsmasik/bulletsmasik.component";
import { AfterBulletsComponent } from "./components/afterbullets/afterbullets.component";
import { ModulesComponent } from "./components/modules/modules.component";
import { RiportComponent } from "./components/riport/riport.component";
import { FeltoltesAlattComponent } from "./views/feltoltesalatt/feltoltesalatt.component";
import { PrivacyPolicyComponent } from "./views/privacypolicy/privacypolicy.component";
import { IratComponent } from "./components/irat/irat.component";
import { KKRComponent } from "./components/kkr/kkr.component";
import { IngatlanComponent } from "./components/ingatlan/ingatlan.component";
import { MuszakiCompontent } from "./components/muszaki/muszaki.component";
import { UgyfelKapuCompontent } from "./components/ugyfelkapu/ugyfelkapu.component";
import { SzamlaComponent } from "./components/szamla/szamla.component";
import { LikvidComponent } from "./components/likvid/likvid.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    KapcsolatComponent,
    NavmenuComponent,
    HeaderComponent,
    FooterComponent,
    CegunkrolComponent,
    SliderComponent,
    BubblesComponent,
    BulletsComponent,
    ModulesComponent,
    BulletsMasikComponent,
    AfterBulletsComponent,
    ScrolltopComponent,
    TestimonialComponent,
    ReferenciakComponent,
    KapcsolaturlapComponent,
    MapsComponent,
    ModalComponent,
    AdatvedelemComponent,
    BevezetoComponent,
    TamogatasComponent,
    TamogatasurlapComponent,
    RiportComponent,
    FeltoltesAlattComponent,
    PrivacyPolicyComponent,
    IratComponent,
    KKRComponent,
    IngatlanComponent,
    MuszakiCompontent,
    UgyfelKapuCompontent,
    SzamlaComponent,
    LikvidComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    SwiperModule,
    InViewportModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({ apiKey: environment.gmapApiURL }),
    SimpleModalModule,
    NgxJsonLdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [CookieService],
  entryComponents: [ModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
