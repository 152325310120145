import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    let now = new Date();

    let datetime = now.toLocaleString("en-us", { year: "numeric" });

    // Insert date and time into HTML
    document.getElementById("datetime").innerHTML = datetime;

  }

}
