import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlbrowserService } from '../../services/plbrowser.service';

@Component({
  selector: 'app-scrolltop',
  templateUrl: './scrolltop.component.html',
  styleUrls: ['./scrolltop.component.css']
})
export class ScrolltopComponent implements OnInit {

  windowScrolled: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 150;
  isBrowser = this.plbrowserService.isBrowser;

  constructor(@Inject(DOCUMENT) private document: Document, private plbrowserService: PlbrowserService) { }

  @HostListener('window:scroll', [])

  onWindowScroll() {
    {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
        this.windowScrolled = true;
      } else if (this.windowScrolled && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
        this.windowScrolled = false;
      }
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  ngOnInit() {
  }

}
