<!-- <h3>Az (<span style="color: #FD916A;">IVR</span>) - Ingatlan Vagyongazdálkodási Rendszer Moduljai:</h3> -->
<div id="IVR-Modulok" class="bulletcontainer" *ngFor="let element of options">
    <div class="bullet" [ngStyle]="{ 'background-image': 'url(' + element.icon + ')' }"></div>
    <div class="title">
        <b>{{ element.title | translate }}</b>
        <!-- <span><div class="inline-div"></span> -->
        <a href="{{element.link}}">
            <!-- <a href="/feltoltesalatt"> -->
            <button class="arrow-bttn">
            <span style="font-weight: 100">&#x24D8;</span>
          </button>
        </a>
    </div>
    <p>{{ element.description | translate }}</p>
    <ul>
        <li *ngFor="let point of element.points">
            {{ point | translate }}
        </li>
    </ul>
</div>
<!-- <div class="chartkep">
    <img *ngIf="languagemenu()" src="/assets/img/chart_v3.svg" alt="chart" height="auto" width="auto" loading="lazy">
    <img *ngIf="!languagemenu()" src="/assets/img/chart_v3_eng.svg" alt="chart" height="auto" width="auto" loading="lazy">
</div> -->
<!-- <div class="cta">
    <button (click)="openModal()">Ingyenes bemutató kérése</button>
</div> -->
