import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-after-bullets',
  templateUrl: './afterbullets.component.html',
  styleUrls: ['./afterbullets.component.css']
})
export class AfterBulletsComponent implements OnInit {

  options = [
    {
      "icon": "/assets/img/solutionv2.png",
      "title": "",
      "points": [
        "ideal-solution.one",
        "ideal-solution.two",
        "ideal-solution.three"
      ]
    } 
  ];

  constructor(private simpleModalService: SimpleModalService) { }

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      {title: 'Ingyenes bemutató kérése'},
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true
      }
    );
  }

  ngOnInit() {
  }

}
