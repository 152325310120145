<div class="tamogatas">
    <h1>Támogatás</h1>
    <div class="formcontainer">
        <app-tamogatasurlap></app-tamogatasurlap>
    </div>
    <div class="textcontainer">
        <h2>GINOP 3.2.2 informatikai pályázat</h2>
        <p>Egyedi ügyviteli rendszerét most uniós támogatással készítjük el!</p>
        <p>Az elmúlt 10 évben mintegy 100 projektünk finanszírozását biztosítottuk uniós támogatásból. A pályázat elkészítését, és a fenntartási időszak alatti monitoring tevékenységet külön díjazás nélkül végezzük.</p>
        <p>Ön se hagyja ki a lehetőséget!</p>
        <img class="ajandek mob" src="assets/img/ajandek.svg">
        <h2>Pályázat főbb paraméterei:</h2>
        <p>Támogatás összege: 5.000.000Ft - 24.000.000 Ft vissza nem térítendő támogatás!</p>
        <img class="ajandek" src="assets/img/ajandek.svg">
        <ul>
            <li>A támogatás mikro-, kis- és középvállalkozások számára elérhető.</li>
            <li>Pest megyén és Budapesten kívül rendelkeznek székhellyel, fiókteleppel vagy telephellyel - és ott legalább 1 fő állandó foglalkoztatottat alkalmaznak vagy alkalmazni fognak legkésőbb a projektmegvalósítás befejezéséig.</li>
            <li>Legalább egy lezárt üzleti év.</li>
            <li>Éves átlagos statisztikai állományi létszáma a támogatási kérelem benyújtását megelőző legutolsó lezárt, teljes üzleti évben minimum 2 fő volt.</li>
        </ul>
    </div>
</div>