<nav>
    <a pageScroll [pageScrollOffset]="470" routerLink="/" fragment="moduljai" href="#IVR-Modulok">
      {{ "header.moduljai" | translate}}</a
  >
  <a
    pageScroll
    [pageScrollOffset]="80"
    routerLink="/"
    fragment="elonyei"
    href="#IVR-elonyei"
    >{{ "header.elonyei" | translate}}</a
  >
  <a routerLink="/cegunkrol" routerLinkActive="active">{{ "header.cegunkrol" | translate}}</a>
    <!-- <a routerLink="/tamogatas" routerLinkActive="active">Támogatás</a> -->
    <a routerLink="/kapcsolat" routerLinkActive="active">{{ "header.kapcsolat" | translate}}</a>
    <button *ngIf="!languagehu" (click)="useLanguag('hu')">hu</button>
    <button *ngIf="languagehu" class="under" (click)="useLanguag('hu')">hu</button>

    <button *ngIf="!languagehu" class="under" (click)="useLanguag('en')">en</button>
    <button *ngIf="languagehu" (click)="useLanguag('en')">en</button>
</nav>
