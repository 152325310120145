<div class="container">
    <h1 style="padding-bottom: 0px; text-align: center;">{{"feltoltesalatt.text" | translate}}</h1>

    <div class="img" style="">
        <img class="kep" src="/assets/img/undraw_under_construction_-46-pa.svg" alt="Pannon Set Kft. csapata">
    </div>
</div>
<div class="fullcont footer">
    <div class="container">
        <a class="more" href="https://www.ps.hu" target="_blank">{{"cegunkrol.tovabbi" | translate}}</a>
    </div>
</div>
