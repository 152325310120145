<div class="container">
    <h1>A Pannon set adatvédelmi és adatkezelési szabályzata</h1>
    <div class="section">
        <div class="titlenumbered">1. Társaság megnevezése</div>
        <p><strong>Adatkezelő megnevezése:</strong> PANNON SET Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság
            <br><strong>Adatkezelő cégjegyzékszáma:</strong> 01-09-908052
            <br><strong>Adatkezelő adószáma:</strong> 14529751-2-43
            <br><strong>Adatkezelő székhelye:</strong> 1237 Budapest, Hrivnák Pál utca 165. fszt. 3.
            <br><strong>Adatkezelő e-elérhetősége:</strong> ps@ps.hu
            <br><strong>Adatkezelő képviselője:</strong> Lesták Károly ügyvezető</p>
    </div>
    <div class="section">
        <div class="titlenumbered">2. Adatkezelés szabályai</div>
        <p>Mivel az információs önrendelkezés minden természetes személy Alaptörvényben rögzített alapjoga, így a
            Társaság eljárásai során csak és kizárólag a hatályos jogszabályok rendelkezései alapján végez adatkezelést.
        </p>
        <p>Személyes adat kezelésére csak jog gyakorlása vagy kötelezettség teljesítése érdekében van lehetőség. A
            Társaság által kezelt személyes adatok magáncélra való felhasználása tilos. Az adatkezelésnek mindenkor meg
            kell felelnie a célhoz kötöttség alapelvének.</p>
        <p>A Társaság személyes adatot csak meghatározott célból, jog gyakorlása és kötelezettség teljesítése érdekében
            kezel, a cél eléréséhez szükséges minimális mértékben és ideig. Az adatkezelés minden szakaszában meg kell
            felelnie a célnak – és amennyiben az adatkezelés célja megszűnt, vagy az adatok kezelése egyébként
            jogellenes, az adatok törlésre kerülnek. A törlésről a Társaságnak az adatot ténylegesen kezelő
            munkavállalója gondoskodik. A törlést a munkavállaló felett munkáltatói jogköröket ténylegesen gyakorló
            személy és az adatvédelmi tisztviselő ellenőrizheti.</p>
        <p>A személyes adatok kezelése kizárólag akkor és annyiban jogszerű, amennyiben legalább az alábbiak egyike
            teljesül:</p>
        <ol>
            <li>az érintett hozzájárulását adta személyes adatainak egy vagy több konkrét célból történő kezeléséhez;
            </li>
            <li>az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a
                szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges;</li>
            <li>az adatkezelés az adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez szükséges;</li>
            <li>az adatkezelés az érintett vagy egy másik természetes személy létfontosságú érdekeinek védelme miatt
                szükséges;</li>
            <li>az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében
                végzett feladat végrehajtásához szükséges;</li>
            <li>az adatkezelés az adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges,
                kivéve, ha ezen érdekekkel szemben elsőbbséget élveznek az érintett olyan érdekei vagy alapvető jogai és
                szabadságai, amelyek személyes adatok védelmét teszik szükségessé, különösen, ha az érintett gyermek.
            </li>
        </ol>
        <p>A Társaság az adat felvétele előtt minden esetben közli az érintettel az adatkezelés célját, valamint az
            adatkezelés jogalapját.</p>
    </div>
    <div class="section">
        <div class="titlenumbered">3. Az érintettek jogai és az érintetti jogok érvényesítése</div>
        <div class="titleinner">Tájékoztatás joga</div>
        <p>A tisztességes és átlátható adatkezelés elve megköveteli, hogy az érintett tájékoztatást kapjon az
            adatkezelés tényéről és céljairól, valamint más tényezőkről.</p>
        <p>Az érintett kérelmére az Adatkezelő tájékoztatást ad az érintett általa kezelt, illetve az általa vagy
            rendelkezése szerint megbízott adatfeldolgozó által feldolgozott adatairól, azok forrásáról, az adatkezelés
            céljáról, jogalapjáról, időtartamáról, az adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő
            tevékenységéről, az adatvédelmi incidens körülményeiről, hatásairól és az elhárítására megtett
            intézkedésekről, továbbá - az érintett személyes adatainak továbbítása esetén - az adattovábbítás
            jogalapjáról és címzettjéről.</p>
        <p>A tájékoztatás főszabály szerint ingyenes, ha a tájékoztatást kérő a folyó évben azonos adatkörre vonatkozóan
            tájékoztatási kérelmet az Adatkezelőhöz még nem nyújtott be. Egyéb esetekben költségtérítés állapítható meg.
            A költségtérítés mértékét a felek között létrejött szerződés is rögzítheti. A már megfizetett
            költségtérítést vissza kell téríteni, ha az adatokat jogellenesen kezelték, vagy a tájékoztatás kérése
            helyesbítéshez vezetett.</p>
        <div class="titleinner">Hozzáférés joga:</div>
        <p>Az érintett jogosult arra, hogy a személyes adatokhoz és a következő információkhoz hozzáférést kapjon:</p>
        <ol>
            <li>az adatkezelés célja;</li>
            <li>az érintett személyes adatok kategóriái;</li>
            <li>azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy
                közölni fogják, ideértve a harmadik országbeli címzetteket, nemzetközi szervezeteket;</li>
            <li>a személyes adatok tárolásának tervezett időtartama, vagy ha ez nem lehetséges ezen időtartam
                meghatározásának szempontjai;</li>
            <li>az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését,
                törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen;</li>
            <li>a valamely felügyeleti hatósághoz címzett panasz benyújtásának joga;</li>
            <li>ha az adatokat nem az érintettől gyűjtötték, a forrásukra vonatkozó minden elérhető információ;</li>
            <li>automatizált döntéshozatal ténye, ideértve a profilalkotást is, valamint legalább ezekben az esetekben
                az alkalmazott logikára és az arra vonatkozó érthető információk, hogy az ilyen adatkezelés milyen
                jelentőséggel bír, és az érintettre nézve milyen várható következményekkel jár.</li>
        </ol>
        <p>Az érintett továbbá jogosult arra is, hogy az adatkezelés tárgyát képező személyes adatok másolatát az
            Adatkezelő az érintett rendelkezésére bocsássa. Az érintett által kért további másolatokért az
            Adatkezelő ésszerű mértékű díjat számíthat fel. Ha az érintett elektronikus úton nyújtotta be a
            kérelmét, az információkat széles körben használt elektronikus formátumban kell rendelkezésre bocsátani.
            kivéve, ha az érintett máshogy kéri.</p>
        <div class="titleinner">Helyesbítés joga</div>
        <p>Az érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül helyesbítse a rá
            vonatkozó pontatlan személyes adatokat, vagy kérje azok kiegészítését.</p>
        <p>A valóságnak nem megfelelő adatot az adatot kezelő szervezeti egység vezetője – amennyiben a szükséges adatok
            és az azokat bizonyító közokiratok rendelkezésre állnak – helyesbíti, a GDPR 17. cikkében meghatározott okok
            fennállása esetén intézkedik a kezelt személyes adat törléséről.</p>
        <div class="titleinner">Törlés és elfeledtetés joga</div>
        <p>Az érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó
            személyes adatokat, ha az alábbi indokok valamelyike fennáll:</p>
        <ol>
            <li>a személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy más módon
                kezelték;</li>
            <li>az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az adatkezelésnek nincs más
                jogalapja;</li>
            <li>az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre;
            </li>
            <li>a személyes adatokat jogellenesen kezelték;</li>
            <li>a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt jogi kötelezettség
                teljesítéséhez törölni kell;</li>
            <li>a személyes adatok gyűjtésére a 16 éven aluli gyermekek részére az információs társadalommal összefüggő
                szolgáltatások kínálásával kapcsolatosan került sor;</li>
        </ol>
        <p>Ha az Adatkezelő nyilvánosságra hozta a személyes adatot, és a személyes adatokra már nincs szükség abból a
            célból, amelyből azokat gyűjtötték vagy más módon kezelték úgy azt törölni köteles, valamint az elérhető
            technológia és a megvalósítás költségeinek figyelembevételével megteszi az észszerűen elvárható lépéseket –
            ideértve technikai intézkedéseket – annak érdekében, hogy tájékoztassa az adatokat kezelő adatkezelőket,
            hogy az érintett kérelmezte tőlük a szóban forgó személyes adatokra mutató linkek vagy e személyes adatok
            másolatának, illetve másodpéldányának törlését.</p>
        <p>A személyes adat az érintett kérelmére sem törölhető a GDPR 17. cikk (3) bekezdésében foglalt adatkezelések
            esetében</p>
        <div class="titleinner">Korlátozáshoz/zároláshoz való jog</div>
        <p>Az érintett jogosult arra, hogy kérésére a Társaság korlátozza az adatkezelést, ha az alábbiak valamelyike
            teljesül:</p>
        <ol>
            <li>az érintett vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra
                vonatkozik, amely lehetővé teszi, hogy az Adatkezelő ellenőrizze a személyes adatok pontosságát;</li>
            <li>az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és ehelyett kéri azok
                felhasználásának korlátozását;</li>
            <li>az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de az érintett igényli
                azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy</li>
            <li>az érintett tiltakozott az adatkezelés ellen, ez esetben a korlátozás arra az időtartamra vonatkozik,
                amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos
                indokaival szemben.</li>
        </ol>
        <div class="titleinner">A személyes adatok helyesbítéséhez vagy törléséhez, illetve az adatkezelés
            korlátozásához kapcsolódó értesítési kötelezettség</div>
        <p>Az Adatkezelő minden olyan címzettet tájékoztat valamennyi helyesbítésről, törlésről vagy adatkezelési
            korlátozásáról, akivel, illetve amellyel a személyes adatot közölték, kivéve, ha ez lehetetlennek bizonyul,
            vagy aránytalan nagy erőfeszítést igényel. Az érintetett kérésére az Adatkezelő tájékoztatja e címzettekről.
        </p>
        <div class="titleinner">Adathordozhatósághoz való jog</div>
        <p>Az érintett – a GDPR 27. cikk (1) bekezdésében foglalt feltételek fennállása esetén - jogosult arra, hogy a
            rá vonatkozó, általa az Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben
            használt, géppel olvasható formában megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik
            adatkezelőnek továbbítsa.</p>
        <div class="titleinner">Tiltakozás joga</div>
        <p>Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes
            adatainak közérdekű vagy az Adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett
            feladattal kapcsolatos kezelése, vagy az Adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítése
            kapcsán végzett kezelése ellen, ideértve az említett rendelkezéseken alapuló profilalkotást is.</p>
        <p>Az érintett tiltakozhat személyes adatának kezelése ellen:</p>
        <ul>
            <li>ha a személyes adatok kezelése vagy továbbítása kizárólag az Adatkezelőre vonatkozó jogi kötelezettség
                teljesítéséhez vagy az Adatkezelő, adatátvevő vagy harmadik személy jogos érdekének érvényesítéséhez
                szükséges, kivéve kötelező adatkezelés esetén;</li>
            <li>ha a személyes adat felhasználása vagy továbbítása közvetlen üzletszerzés, közvélemény-kutatás vagy
                tudományos kutatás céljára történik; valamint</li>
            <li>törvényben meghatározott egyéb esetben.</li>
        </ul>
        <p>Az Adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a kérelem beérkezésétől számított egy
            hónapon belül tájékoztatja az érintettet a kérelem nyomán hozott intézkedésekről. Szükség esetén, figyelembe
            véve a kérelem összetettségét és a kérelmek számát, ez a határidő további két hónappal meghosszabbítható. A
            határidő meghosszabbításáról az adatkezelő a késedelem okainak megjelölésével a kérelem kézhezvételétől
            számított egy hónapon belül tájékoztatja az érintettet. Ha az érintett elektronikus úton nyújtotta be a
            kérelmet, a tájékoztatást lehetőség szerint elektronikus úton kell megadni, kivéve, ha az érintett azt
            másként kéri. </p>
        <p>Ha az Adatkezelő az érintett tiltakozásának megalapozottságát megállapítja, az adatkezelést - beleértve a
            további adatfelvételt és adattovábbítást is – megszünteti, az adatokat zárolja, valamint a tiltakozásról,
            továbbá az annak alapján tett intézkedésekről értesíti mindazokat, akik részére a tiltakozással érintett
            személyes adatot korábban továbbította és akik kötelesek intézkedni a tiltakozási jog érvényesítése
            érdekében.</p>
        <div class="titleinner">Bírósághoz fordulás joga és panasztétel joga</div>
        <p>Az Adatkezelő az érintett adatainak jogellenes kezelésével vagy az adatbiztonság követelményeinek
            megszegésével másnak okozott kárt, illetve az általa vagy az általa igénybe vett adatfeldolgozó által
            okozott személyiségi jogsértés esetén járó sérelemdíjat is megtéríti. Az Adatkezelő mentesül az okozott
            kárért való felelősség és a sérelemdíj megfizetésének kötelezettsége alól, ha bizonyítja, hogy a kárt vagy
            az érintett személyiségi jogának sérelmét az adatkezelés körén kívül eső elháríthatatlan ok idézte elő.
            Ugyanígy nem téríti meg a kárt, amennyiben az a károsult szándékos vagy súlyosan gondatlan magatartásából
            származott.</p>
        <p>Ha az Adatkezelő nem tesz intézkedéseket az érintett kérelme nyomán, késedelem nélkül, de legkésőbb a kérelem
            beérkezésétől számított egy hónapon belül tájékoztatja az érintettet az intézkedés elmaradásának okairól,
            valamint arról, hogy az érintett panaszt nyújthat be NAIH-nál (1125 Budapest, Szilágyi Erzsébet fasor 22/C.)
            és élhet - lakóhelye vagy tartózkodási helye szerint illetékes törvényszéknél is - bírósági jogorvoslati
            jogával.</p>
    </div>
    <div class="section">
        <div class="titlenumbered">4. A Pannon Set Kft. honlapjának használata során megvalósuló adatkezelések</div>
        <div class="titleinner">Az adatkezelés helyszínei:</div>
        <p><strong>Székhely:</strong> 1237 Budapest, Hrivnák Pál utca 165. fszt. 3.<br>
            <strong>Fióktelepe(i):</strong> 1117 Budapest, Fehérvári út 24. 4. em. 1.</p>
        <div class="titleinner">Kapcsolatfelvétel</div>
        <p>A Társaság saját honlappal rendelkezik, mely az alábbi címen érhető el: <a href="https://www.ps.hu"></a></p>
        <p>A honlapot a Társaság üzemelteti. <br>A Társaság lehetőséget biztosít arra, hogy a honlapokon keresztül a
            látogató felvegye a Társasággal a kapcsolatot. Egy form kitöltésével a látogató megadhatja a
            kapcsolatfelvételhez szükséges releváns adatokat. Az adatok elküldésére azonban csak akkor van lehetősége az
            érintettnek, ha elfogadja a Társaság adatkezelési szabályait, ezt egy jelölőnégyzet bejelölésével teheti
            meg, máskülönben nem tudja megküldeni az üzenetét.</p>
        <p><strong>adatkezelés célja:</strong> a Társasággal történő kapcsolatfelvétel elősegítése<br>
            <strong>kezelt adatok köre:</strong> név, cégnév, e-mail cím, telefonszám, üzenet szövege<br>
            <strong>adatkezelés jogalapja:</strong> a GDPR 6. cikk (1) a) szerinti érintetti hozzájárulás<br>
            <strong>adattárolás határideje:</strong> a kapcsolatfelvételi ügy elintézéséig (a cél megvalósulásáig)<br>
            <strong>adattárolás módja:</strong> elektronikus</p>
        <div class="titleinner">Cookies</div>
        <p>A látogatókról személyhez kötött információkat automatikusan gyűjt a weblap Google Analytics által (IP cím,
            tartózkodási idő, helyrajzi adatok felhasználói szokások stb.), melyek a honlap látogatottsági
            statisztikáját szolgálják. Ennek során sütik (cookies) kerülnek elhelyezésre a látogató számítógépein.</p>
        <p>A honlap megtekintése során automatikusan rögzítésre kerül a felhasználó látogatásának kezdő és befejező
            időpontja, illetve egyes esetekben – a felhasználó számítógépének beállításától függően – a böngésző és az
            operációs rendszer típusa. Ezen adatokból a rendszer automatikusan statisztikai adatokat generál.</p>
        <p><strong>adatkezelés célja:</strong> a honlap látogatóiról személyhez kötött, automatikus adatrögzítés a<br>
            felhasználási szokásokról Google Analytics, valamint cookiek segítségével<br>
            <strong>kezelt adatok köre:</strong> IP cím, tartózkodási idő, helyrajzi adatok felhasználói szokások,<br>
            számítógép beállításától függően – a böngésző és az operációs rendszer típusa<br>
            <strong>adatkezelés jogalapja:</strong> a GDPR 6. cikk (1) a) szerinti érintetti hozzájárulás<br>
            <strong>adattárolás határideje:</strong> az adatkezelési cél megvalósulásáig, de maximum a rögzítéstől
            számított 2 évig<br>
            <strong>adattárolás módja:</strong> elektronikus</p>
    </div>
    <div class="section">
        <div class="titlenumbered">5. Jelen tájékoztatóban nem meghatározott kérdések</div>
        <p>A jelen tájékoztatóban nem meghatározott kérdésekben az Infotv. és az Európai Parlament és a Tanács (EU) 2016/679 rendelete (GDPR) szabályai az irányadóak.</p>
    </div>
</div>