<h2>Érdeklődöm</h2>
<form [formGroup]="tamogatasForm" class="tamform" (ngSubmit)="onSubmit()" *ngIf="!submitted">
    <div class="formrow">
        <input id="nev" type="text" name="nev" required formControlName="nev" placeholder="Név"
            [class.err]="errors.nev">
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.nev">{{errors.nev}}</div>
    </div>
    <div class="formrow">
        <input id="cegnev" type="text" name="cegnev" formControlName="cegnev" placeholder="Cégnév">
    </div>
    <div class="formrow">
        <input id="email" type="text" name="email" formControlName="email" placeholder="E-mail cím"
            [class.err]="errors.email">
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.email">{{errors.email}}</div>
    </div>
    <div class="formrow">
        <input id="mobil" type="text" name="mobil" required formControlName="mobil" placeholder="Mobiltelefonszám"
            [class.err]="errors.nev">
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.mobil">{{errors.mobil}}</div>
    </div>
    <div class="formrow">
        <input type="checkbox" name="ugyviteli" id="ugyviteli" formControlName="ugyviteli">
        <label for="ugyviteli">Érdekel az ügyviteli rendszer.</label>
    </div>
    <div class="formrow">
        <input type="checkbox" name="palyazat" id="palyazat" formControlName="palyazat">
        <label for="palyazat">Érdekel a pályázat.</label>
    </div>
    <div class="formrow">
        <input type="checkbox" name="aszf" id="aszf" formControlName="aszf" required>
        <label for="aszf">Elolvastam és elfogadom az <a routerLink="/adatvedelem" target="_blank">adatvédelmi
                nyilatkozatban</a>
            foglaltakat.</label>
        <div class="rqrd">*</div>
        <div class="errorfield" *ngIf="errors.aszf">{{errors.aszf}}</div>
    </div>
    <button type="submit">Küldés</button> <!-- [disabled]="!tamogatasForm.valid" -->
</form>
<div *ngIf="submitted" class="thankyou">
    <span class="name">Kedves {{tamogatasForm.value.nev}}!</span><br>
    Köszönjük a kapcsolatfelvételt! Munkatársunk hamarosan jelentkezik.
</div>