<body>
    <div>
        <h2 class="background double" style="text-align: center;"><strong>{{"yellow-dot.menu-eight-title" |
                translate}}</strong></h2>
        <div class="pad">
            <!-- <p style="text-align: center;"><strong>A Netfaktor működése</strong></p> -->
            <ul class="margin-ul">
                <li> {{"riport.li.one" | translate}}</li>
                <li>{{"riport.li.two" | translate}}</li>
                <li>{{"riport.li.three" | translate}}</li>
                <li>{{"riport.li.four" | translate}}</li>
                <li>{{"riport.li.five" | translate}}</li>
            </ul>

        </div>
        <div style="position: relative; text-align: right; padding-right: 200px; padding-bottom: 50px;">
            <button _ngcontent-serverapp-c13="" class="arrow-bttn"><a style=" text-decoration: none;"
                    onclick="history.back()"><span _ngcontent-serverapp-c13=""
                        style="font-size: 30px; text-transform:none; font-weight: 100; font-family: sans-serif !important;">&lt;&lt;
                        {{"home" | translate}}</span></a></button>
        </div>
    </div>

</body>