<div class="imgcont">
    <img class="white-shape" src="/assets/img/white-shape.svg">
    <img class="refseparator rellax2" data-rellax-speed="1" height="auto" width="auto" loading="lazy" src="/assets/img/shutterstock_1712828755.jpg" alt="Pannon Set referenciák">
</div>
<div class="container" snInViewport (inViewportChange)="onInViewportChange($event)">
    <div class="refblock" *ngFor="let element of references">
        <div class="logo">
            <img alt="logos" width="auto" loading="lazy" [src]="element.logo" [alt]="element.name">
        </div>
        <div class="name">
            {{element.name}}
        </div>
        <div class="description" [innerHtml]="element.description | translate">

        </div>
    </div>
</div>
<div style="padding-left: 45%; padding-bottom: 5ch;">
    <a href="/tovabbireferenciak">
        <button class="arrow-bttn">
        <!-- <span style="font-size: 30px; text-transform:none;">{{"tovabbi.referenciak.button" | translate}}</span> -->
    </button>
    </a>
</div>
