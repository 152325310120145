import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {

  constructor() { }

  title = 'Pannon Set Kft.';
  lat = 47.473935;
  lng = 19.046466;
  zoom = 14;
  mapType = 'roadmap';

  ngOnInit() {
  }

}
