import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-szamla',
  templateUrl: './szamla.component.html',
  styleUrls: ['./szamla.component.css']
})

export class SzamlaComponent implements OnInit {
  constructor(private cookieService: CookieService) { }
  languagehu = false;

  getCookie(name: string) {
    return this.cookieService.get(name);
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }


  ngOnInit() {
  }

}
