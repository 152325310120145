import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalComponent } from '../modal/modal.component';
import { ConversionService } from 'src/app/services/conversion.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {
  constructor(private simpleModalService: SimpleModalService,
    private conversionService: ConversionService,
    private cookieService: CookieService
  ) { }

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  public swiperconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    speed: 1600,
    pagination: this.pagination,
    loop: true,
    keyboard: true,
    lazy: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    }
  };

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      { title: 'Kapcsolat' },
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        autoFocus: true
      }
    );
  }


  getCookie(name) {
    return this.cookieService.get(name);
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }
  ngOnInit() {
    window.scrollTo(0, 0);

  }
  OnInit(){
    
  }
}
