import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tamogatas',
  templateUrl: './tamogatas.component.html',
  styleUrls: ['./tamogatas.component.css']
})
export class TamogatasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
