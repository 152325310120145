<div class="header">
    <a routerLink="/">
        <img class="pslogo" src="assets/img/pannon-set-logo-fekete.svg" alt="Pannon Set">
        <div class="sub">IVR <span>{{"under.slider.title" | translate}} </span></div>
    </a>
    <button class="hamburger hamburger--spin" type="button" [class.is-active]="mobmenu" (click)="mobilemenu()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <app-navmenu [class.show]="mobmenu" (click)="mobmenu = false"></app-navmenu>
</div>
