import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

const APP_TITLE = 'Pannon Set Kft.';
const SEPARATOR = ' > ';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  currentTitle = '';
  currentDescription = '';
  defaultDescription = 'Gyártástervezés - termelésirányítás';
  currentShareimage = environment.host + '';
  defaultShareimage = environment.host + '/og-image.png';
  metarobots = '';

  static ucFirst(text: string) {
    if (!text) { return text; }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  init() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route) => route.snapshot),
      map((snapshot) => {
        if (snapshot.data.description) {
          this.currentDescription = snapshot.data.description;
        } else {
          this.currentDescription = this.defaultDescription;
        }
        if (snapshot.data.ogimage) {
          this.currentShareimage = snapshot.data.ogimage;
        } else {
          this.currentShareimage = this.defaultShareimage;
        }
        if (snapshot.data.metarobots) {
          this.metarobots = snapshot.data.metarobots;
        } else {
          this.metarobots = '';
        }
        if (snapshot.data.title) {
          this.currentTitle = snapshot.data.title;
          if (snapshot.paramMap.get('id') !== null) {
            return snapshot.data.title + SEPARATOR + snapshot.paramMap.get('id');
          }
          return snapshot.data.title;
        } else {
          // If not, we do a little magic on the url to create an approximation
          return this.router.url.split('/').reduce((acc, frag) => {
            if (acc && frag) { acc += SEPARATOR; }
            this.currentTitle = acc + SeoService.ucFirst(frag);
            return acc + SeoService.ucFirst(frag);
          });
        }
      }))
      .subscribe((pathString) => {
        this.titleService.setTitle(`${pathString} | ${APP_TITLE}`);
        this.metaService.updateTag(
          { name: 'description', content: this.currentDescription },
          `name='description'`
        );
        this.metaService.updateTag(
          { property: 'og:url', content: environment.host + this.router.url },
          `property='og:url'`
        );
        this.metaService.updateTag(
          { property: 'og:title', content: this.currentTitle },
          `property='og:title'`
        );
        this.metaService.updateTag(
          { property: 'og:description', content: this.currentDescription },
          `property='og:description'`
        );
        this.metaService.updateTag(
          { property: 'og:image', content: this.currentShareimage },
          `property='og:image'`
        );
        if (this.metarobots !== '' && this.metaService.getTag('name= "robots"')) {
          this.metaService.updateTag(
            { name: 'robots', content: this.metarobots },
            `name='robots'`
          );
        } else if (this.metarobots !== '' && !this.metaService.getTag('name= "robots"')) {
          this.metaService.addTag(
            { name: 'robots', content: this.metarobots }
          );
        } else if (this.metarobots === '' && this.metaService.getTag('name= "robots"')) {
          this.metaService.removeTag('name = "robots"');
        }
      });
  }
}
