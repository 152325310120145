<div class="container">
    <h1>The data protection and data management policy of Pannon Set</h1>
    <div class="section">
        <div class="titlenumbered">1. Company Name</div>
        <p><strong>Data Controller:</strong> PANNON SET Trading and Service Limited Liability Company
            <br><strong>Data Controller registration number:</strong> 01-09-908052
            <br><strong>Data Controller tax number:</strong> 14529751-2-43
            <br><strong>Data Controller headquarters:</strong> 1237 Budapest, Hrivnák Pál street 165. fszt. 3.
            <br><strong>Data Controller e-contact:</strong> ps@ps.hu
            <br><strong>Data Controller representative:</strong> Károly Lesták, managing director</p>
    </div>
    <div class="section">
        <div class="titlenumbered">2. Rules of Data Management</div>
        <p>Since informational self-determination is a fundamental right of every natural person as enshrined in the Fundamental Law, the Company conducts data processing solely and exclusively based on the provisions of applicable laws.</p>
        <p>Personal data may only be processed for the exercise of rights or the performance of obligations. The use of personal data processed by the Company for private purposes is prohibited. Data processing must always comply with the principle of purpose
            limitation.
        </p>
        <p>The Company processes personal data only for specified purposes, for the exercise of rights and the performance of obligations, to the extent necessary and for the minimum period necessary to achieve the purpose. At all stages of data processing,
            it must comply with the purpose, and if the purpose of the data processing has ceased or the processing of the data is otherwise unlawful, the data will be deleted. Deletion is carried out by the employee actually processing the data. Deletion
            can be verified by the person exercising the employer's rights over the employee and the data protection officer.</p>
        <p>Processing of personal data is lawful only if at least one of the following applies:</p>
        <ol>
            <li>the data subject has given consent to the processing of their personal data for one or more specific purposes;</li>
            <li>processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract;</li>
            <li>processing is necessary for compliance with a legal obligation to which the controller is subject;</li>
            <li>processing is necessary in order to protect the vital interests of the data subject or of another natural person;</li>
            <li>processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
            <li>processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require
                protection of personal data, in particular where the data subject is a child.</li>
        </ol>
        <p>The Company always informs the data subject of the purpose of data processing and the legal basis for the processing before collecting the data.</p>
    </div>
    <div class="section">
        <div class="titlenumbered">3. Rights of Data Subjects and Exercise of Data Subject Rights</div>
        <div class="titleinner">Right to Information</div>
        <p>The principle of fair and transparent data processing requires that the data subject be informed of the fact and purposes of the data processing, as well as other factors.</p>
        <p>At the request of the data subject, the Data Controller provides information on the data processed by the data subject, or processed by or on behalf of the data controller, including the source of the data, the purpose of the processing, the legal
            basis, the duration, the name and address of the data processor, and their activities related to data processing, the circumstances of the data protection incident, its effects and the measures taken to remedy it, and, if personal data is
            transmitted, the legal basis and recipient of the data transmission.</p>
        <p>Information is generally provided free of charge if the requester has not previously requested information about the same data set from the Data Controller during the current year. In other cases, a fee may be charged. The amount of the fee may
            also be specified in the contract between the parties. Any fees already paid must be refunded if the data was processed unlawfully or if the request for information led to correction.</p>
        <div class="titleinner">Right of Access:</div>
        <p>The data subject is entitled to access personal data and the following information:</p>
        <ol>
            <li>the purpose of the processing;</li>
            <li>the categories of personal data concerned;</li>
            <li>the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations;</li>
            <li>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</li>
            <li>the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing of personal data concerning the data subject or to object to such processing;</li>
            <li>the right to lodge a complaint with a supervisory authority;</li>
            <li>where the personal data are not collected from the data subject, any available information as to their source;</li>
            <li>the existence of automated decision-making, including profiling, referred to in Articles 22(1) and (4) and, at least in those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences
                of such processing for the data subject.</li>
        </ol>
        <p>The Data Controller provides a copy of the personal data subject to processing. The copy may be electronic if requested by the data subject and if the right to information is exercised by electronic means. The Data Controller may not charge a
            fee for the first electronic copy.</p>
    </div>
    <div class="section">
        <div class="titlenumbered">4. Objection and Complaint</div>
        <p>The data subject may object to the processing of personal data if the processing of the data is necessary:</p>
        <ul>
            <li>for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
            <li>for the purposes of the legitimate interests pursued by the controller or by a third party.</li>
        </ul>
        <p>The data subject is entitled to file a complaint with the competent authority in the event of a violation of their rights or if their request is rejected. The competent authority is the Hungarian National Authority for Data Protection and Freedom
            of Information (NAIH).</p>
    </div>
</div>