import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { CegunkrolComponent } from './views/cegunkrol/cegunkrol.component';
import { KapcsolatComponent } from './views/kapcsolat/kapcsolat.component';
import { AdatvedelemComponent } from './views/adatvedelem/adatvedelem.component';
import { TamogatasComponent } from './views/tamogatas/tamogatas.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { RiportComponent } from './components/riport/riport.component';
import { FeltoltesAlattComponent } from './views/feltoltesalatt/feltoltesalatt.component';
import { PrivacyPolicyComponent } from './views/privacypolicy/privacypolicy.component';
import { IratComponent } from './components/irat/irat.component';
import { KKRComponent } from './components/kkr/kkr.component';
import { IngatlanComponent } from './components/ingatlan/ingatlan.component';
import { MuszakiCompontent } from './components/muszaki/muszaki.component';
import { UgyfelKapuCompontent } from './components/ugyfelkapu/ugyfelkapu.component';
import { SzamlaComponent } from './components/szamla/szamla.component';
import { LikvidComponent } from './components/likvid/likvid.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Kapcsolat',
      description: 'Gyártó cégek számára fejlesztett személyre szabott szoftverek. Gyártástervezés, termelésirányítás, gyártáskövetés.'
    }
  },
  {
    path: 'cegunkrol',
    component: CegunkrolComponent,
    data: {
      title: 'Cégünkről',
      description: 'Több mint 20 éves fennállásunk kezdete óta felhőalapú vállalatirányítási rendszereket fejlesztünk.'
    }
  },
  {
    path: 'kapcsolat',
    component: KapcsolatComponent,
    data: {
      title: 'Kapcsolat',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },  
  {
    path: 'feltoltesalatt',
    component: FeltoltesAlattComponent,
    data: {
      title: 'FeltoltesAlatt',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'irat',
    component: IratComponent,
    data: {
      title: 'Irat',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'kkr',
    component: KKRComponent,
    data: {
      title: 'KKR',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'ingatlan',
    component: IngatlanComponent,
    data: {
      title: 'Ingatlan',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'muszak',
    component: MuszakiCompontent,
    data: {
      title: 'Műszak',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'ugyfelkapu',
    component: UgyfelKapuCompontent,
    data: {
      title: 'Űgyfélkapu',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'szamla',
    component: SzamlaComponent,
    data: {
      title: 'Számla',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'likvid',
    component: LikvidComponent,
    data: {
      title: 'Likvid',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'riport',
    component: RiportComponent,
    data: {
      title: 'Riport',
      description: 'Kérdése van? Pannon Set Kft. 1237 Bp. Hrivnák Pál u. 165/3., Telefon: +36 1/789-0575, Mobil: +36 20/936-4149'
    }
  },
  {
    path: 'adatvedelem',
    component: AdatvedelemComponent,
    data: {
      title: 'Adatvédelem',
      metarobots: 'noindex,nofollow'
    }
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacypolicy',
      metarobots: 'noindex,nofollow'
    }
  },
  {
    path: 'tamogatas',
    component: TamogatasComponent,
    data: {
      title: 'Támogatás',
      description: 'GINOP 3.2.2 Informatikai Pályázat. Egyedi ügyviteli rendszerét most uniós támogatással készítjük el!'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: '404',
      metarobots: 'noindex,nofollow'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,
    // enableTracing: true,
    anchorScrolling: 'enabled',
    // scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: "reload",
    scrollOffset: [80, 0] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
