import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feltoltesalatt',
  templateUrl: './feltoltesalatt.component.html',
  styleUrls: ['./feltoltesalatt.component.css']
})
export class FeltoltesAlattComponent implements OnInit {

  constructor() { }

  openPs() {
    window.location.assign('http://external-url');
  }

  ngOnInit() {
    window.scrollTo(0, 200);
  }

}
