<div class="bubbles" id="IVR-elonyei" snInViewport (inViewportChange)="onInViewportChange($event)">
    <div id="bubbles" class="anchor" style="top: -120px;"></div>
    <h2 style="font-size:35px;"><span style="color: #FD916A;">IVR</span> {{"button.elonyei" | translate}}</h2>
    <div class="gridbox">
        <div snInViewport *ngFor="let bubble of bubbles" class="bubble">
            <div class="img-div">
                <p class="white-text img-div">{{bubble.text | translate}}</p>
            </div>
            <div class="more-text">
                <b class="small-bubble " style="font-weight: 500;">{{bubble.description | translate}}</b>
                <!-- <button (click)="openModal()">Kapcsolat</button> -->
            </div>
        </div>
    </div>
</div>
