<body>
    <div>
        <h2 class="background double" style="text-align: center;"><strong>{{"yellow-dot.menu-three-title" |
                translate}}</strong></h2>
        <div class="pad">
            <!-- <p style="text-align: center;"><strong>A Netfaktor működése</strong></p> -->
            <ul class="margin-ul">
                <li>{{"szamla.li.one" | translate}}</li>
                <li>{{"szamla.li.two" | translate}}</li>
                <li>{{"szamla.li.three" | translate}}</li>
                <li>{{"szamla.li.four" | translate}}</li>
            </ul>
            <!-- <ul class="margin-ul">
                <li>Centralizált tárolást biztosít a dokumentumoknak, így könnyen megtalálhatók és elérhetők a jogosult felhasználók számára.</li>
                <li>Javítja a munkafolyamatok hatékonyságát a dokumentumok gyorsabb keresésével és a papíralapú adminisztráció csökkentésével.</li>
            </ul>
            <ul class="margin-ul">
                <li>Hozzáférési jogosultságokat állíthat be a dokumentumokhoz, így csak az egyes felhasználók/felhasználói csoportok a jogosultsági szintjüknek megfelelően férhetnek hozzá a bizalmas információkhoz.</li>
                <li>Biztonságos tárolást biztosít a dokumentumoknak, védve azokat a jogosulatlan hozzáféréstől, a megsemmisüléstől és a manipulációtól. </li>
            </ul>
            <ul class="margin-ul" style="padding-bottom: 50px;">
                <li>Verziókövetést biztosít a dokumentumoknak, így nyomon követhető a módosítások története.</li>
                <li>Segít a dokumentumok archiválásában és a jogszabályi megfelelés biztosításában.</li>
                <li>Nagy előnye hogy integrált az összes modulban keletkező dokumentum automatikusan iktatásra kerül és (iktató számot kap) Könnyen kezelhető felülettel rendelkezik, amellyel a felhasználók gyorsan elsajátíthatják a használatát.</li>
                <li>Javítja a csapatmunka hatékonyságát a dokumentumok egyszerű megosztásával és együttműködéssel. </li>
                <li>Támogatja a távmunkát, lehetővé téve a dokumentumok elérését bárhonnan, bármikor. </li>
                <li>Skálázható megoldás, amely kis és nagyobb intézmények számára is egyaránt alkalmas.</li>
                <li>A PS DMS egy értékes eszköz cégek / intézmények számára a dokumentumok hatékonyabb kezeléséhez és a munkafolyamatok optimalizálásához.</li>
            </ul> -->
        </div>
        <div style="position: relative; text-align: right; padding-right: 200px; padding-bottom: 50px;">
            <button _ngcontent-serverapp-c13="" class="arrow-bttn"><a style=" text-decoration: none;"
                    onclick="history.back()"><span _ngcontent-serverapp-c13=""
                        style="font-size: 30px; text-transform:none; font-weight: 100; font-family: sans-serif !important;">&lt;&lt;
                        {{"home" | translate}}</span></a></button>
        </div>
    </div>

</body>