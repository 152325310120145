import { Component, OnInit } from '@angular/core';
import { ConversionService } from '../../services/conversion.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-kapcsolat',
  templateUrl: './kapcsolat.component.html',
  styleUrls: ['./kapcsolat.component.css']
})

export class KapcsolatComponent implements OnInit {
  titlefromParent = 'contact.kerdese';
  workers = [
    {
      name : 'Lesták Károly',
      pos : 'contact.ugyvezeto',
      email : 'lestakk@ps.hu',
      phone : '+36 20/936-4149'
    },
    {
      name : 'Nimmerfroh Károly',
      pos : 'contact.szoftverfejl',
      email : 'nkaroly@ps.hu',
      phone : '+36 20/334-1654'
    },
    {
      name : 'Komáromi Tamás',
      pos : 'contact.vezetofejl',
      email : 'tamas@ps.hu',
      phone : '+36 20/475-8784'
    },
    {
      name : 'Herr Béla',
      pos : 'contact.projekt',
      email : 'herrb@ps.hu',
      phone : '+36 20/489-8557'
    },
    {
      name : 'Márkus Levente',
      pos : 'contact.ertekesitesi',
      email : 'markus.levente@ps.hu',
      phone : '+36 20/290-1067'
    }
  ];

  constructor(private conversionService: ConversionService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
  }

  convfire(){
    this.conversionService.conversionFire();
  }

  getCookie(name) {
    return this.cookieService.get(name);
  }

  languagemenu() {
    return this.getCookie("lang") == "hu";
  }


}
