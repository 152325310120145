<h3>{{"ivr.solution" | translate}}<span style="color:#FFA500;">{{"ivr.solution.second" | translate}}</span>{{"ivr.solution.third" | translate}}</h3>
<div class="bulletcontainer" *ngFor="let element of options">
    <div class="bullet" [ngStyle]="{'background-image': 'url(' + element.icon + ')'}">
    </div>
    <div class="title" style="font-weight: bold;">
        {{element.title}}
    </div>
    <ul class="title">
        <li *ngFor="let point of element.points">
            {{point | translate}}
        </li>
    </ul>
</div>
<!-- <div class="cta">
    <button (click)="openModal()">Ingyenes bemutató kérése</button>
</div> -->
