import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { Response } from 'express';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  // https://stackoverflow.com/questions/46219720/angular-universal-generate-404-and-other-http-codes-header
  private response: Response;

  constructor(@Optional() @Inject(RESPONSE) response: any) {
    this.response = response;
  }

  ngOnInit() {
    if (this.response) {
      this.response.status(404);
    }
  }

}
