import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  constructor() { }


  conversionFire() {
    if (environment.production) {
      (window as any).dataLayer.push({
        event: 'adwordsconversionfire'
      });
    }
  }

  conversionclick() {
    const hotpoints = document.querySelectorAll('.adsconvfire');
    const pthis = this;
    hotpoints.forEach(element => {
      element.addEventListener('click', function () {
        pthis.conversionFire();
      })
    });
  }

  init(){
    this.conversionclick();
  }
}
