<app-slider></app-slider>
<div class="separatorimgcont">
    <img class="separatorimg rellax" alt="lisbon-portugal-streetview" loading="lazy" height="auto" width="auto" data-rellax-speed="5" src="/assets/img/shutterstock_1415297639.jpg">
</div>
<app-bevezeto></app-bevezeto>
<!-- <app-bubbles></app-bubbles> -->
<app-bullets-masik></app-bullets-masik>
<app-modules></app-modules>
<!-- <app-bullets></app-bullets> -->
<app-after-bullets></app-after-bullets>
<app-referenciak></app-referenciak>
<app-testimonial></app-testimonial>
