<div class="kapcsolat">
    <h1 *ngIf="!languagemenu()">Contact</h1>
    <h1 *ngIf="languagemenu()">Kapcsolat</h1>
    <div class="map">
        <app-maps></app-maps>
    </div>
    <div class="companyinfocont" *ngIf="languagemenu()">
        <div class="companyinfo">
            <img alt="levelezo" src="assets/img/kapcsolat-boritek.svg">
            <span>
        Pannon Set Kft.<br>
        1237 Bp. Hrivnák Pál u. 165/3.
      </span>
        </div>
        <div class="companyinfo">
            <img alt="bankszamla" src="assets/img/kapcsolat-epulet.svg">
            <span>
        Adószám: 14529751-2-43<br>
        Bankszámla szám:<br>
        10300002-10634159-49020019
      </span>
        </div>
        <div class="companyinfo">
            <img alt="email" src="assets/img/kapcsolat-mail.svg">
            <span>
        <a class="adsconvfire" (click)="convfire()" href="mailto:ertekesites@ps.hu">ertekesites@ps.hu</a><br>
        <a class="adsconvfire" (click)="convfire()" href="mailto:szupport@ps.hu">szupport@ps.hu</a><br>
      </span>
        </div>
        <div class="companyinfo">
            <img alt="telefon" src="assets/img/kapcsolat-mobil.svg">
            <span>
        <a class="adsconvfire" (click)="convfire()" href="tel:0617890575">+36 1/789-0575</a><br>
        <a class="adsconvfire" (click)="convfire()" href="tel:06209364149">+36 20/936-4149</a>
      </span>
        </div>
        <div class="companyinfo">
            <img alt="iroda" src="assets/img/kapcsolat-iroda.svg">
            <span>Iroda:<br>
        1117 Bp. Fehérvári út. 24. IV/1.</span>
        </div>
        <div class="companyinfo">
            <img alt="nyitvatartas" src="assets/img/kapcsolat-ora.svg">
            <span>
        Nyitvatartás:<br>
        munkanapokon 9-17 óra között
      </span>
        </div>
        <div class="psreadmore"><a href="https://www.ps.hu">{{"cegunkrol.tovabbi.two" | translate}}</a></div>
    </div>

    <!-- ANGOL -->
    <div class="companyinfocont" *ngIf="!languagemenu()">
        <div class="companyinfo">
            <img alt="levelezo" src="assets/img/kapcsolat-boritek.svg">
            <span>
                Pannon Set Kft.<br>
                1237 Budapest. Hrivnák Pál st. 165/3.
            </span>
        </div>
        <div class="companyinfo">
            <img alt="bankszamla" src="assets/img/kapcsolat-epulet.svg">
            <span>
                Tax ID: 14529751-2-43<br>
                Bank account number:<br>
                10300002-10634159-49020019
            </span>
        </div>
        <div class="companyinfo">
            <img alt="email" src="assets/img/kapcsolat-mail.svg">
            <span>
                <a class="adsconvfire" (click)="convfire()" href="mailto:ertekesites@ps.hu">ertekesites@ps.hu</a><br>
                <a class="adsconvfire" (click)="convfire()" href="mailto:szupport@ps.hu">szupport@ps.hu</a><br>
            </span>
        </div>
        <div class="companyinfo">
            <img alt="telefon" src="assets/img/kapcsolat-mobil.svg">
            <span>
                <a class="adsconvfire" (click)="convfire()" href="tel:0617890575">+36 1/789-0575</a><br>
                <a class="adsconvfire" (click)="convfire()" href="tel:06209364149">+36 20/936-4149</a>
            </span>
        </div>
        <div class="companyinfo">
            <img alt="iroda" src="assets/img/kapcsolat-iroda.svg">
            <span>Office:<br>
                1117 Budapest. Fehérvári st. 24. IV/1.</span>
        </div>
        <div class="companyinfo">
            <img alt="nyitvatartas" src="assets/img/kapcsolat-ora.svg">
            <span>
                Opening hours:<br>
                weekdays between 9 am and 5 pm
            </span>
        </div>
        <div class="psreadmore"><a href="https://www.ps.hu">{{"cegunkrol.tovabbi.two" | translate}}</a></div>
    </div>

    <!-- asd -->

    <div class="sep">
    </div>
    <div class="workercont">
        <div class="worker" *ngFor="let worker of workers">
            <div class="name">{{worker.name}}</div>
            <div class="position">{{worker.pos | translate}}</div>
            <div class="email">
                <img src="assets/img/kapcsolat-boritek-black.svg">
                <span>E-mail:</span> <a class="adsconvfire" (click)="convfire()" href="mailto:{{worker.email}}">{{worker.email}}</a>
            </div>
            <div class="mobil" *ngIf="!languagemenu()">
                <img src="assets/img/kapcsolat-mobil-black.svg">
                <span>Phone:</span> <a class="adsconvfire" (click)="convfire()" href="tel:{{worker.phone}}">{{worker.phone}}</a>
            </div>
            <div class="mobil" *ngIf="languagemenu()">
                <img src="assets/img/kapcsolat-mobil-black.svg">
                <span>Mobil:</span> <a class="adsconvfire" (click)="convfire()" href="tel:{{worker.phone}}">{{worker.phone}}</a> </div>
        </div>
    </div>
    <div class="formcontainer">
        <app-kapcsolaturlap [formtitle]="titlefromParent | translate"></app-kapcsolaturlap>
    </div>
</div>
