import { Component, OnInit, AfterViewInit } from "@angular/core";
import { SimpleModalService } from "ngx-simple-modal";
import { ModalComponent } from "../modal/modal.component";
import { PlbrowserService } from "../../services/plbrowser.service";

@Component({
  selector: "app-modules",
  templateUrl: "./modules.component.html",
  styleUrls: ["./modules.component.css"],
})
export class ModulesComponent implements OnInit, AfterViewInit {
  bubbles = [   
  {
      description:
      "modules.ivr",
      text: "modules.one.title",
  },
  {
      description:
      "modules.hatekony",
      text: "modules.hatekony.title",
  },
  {
    description:
    "modules.atlathato",
    text: "modules.atlathato.title",
    },
    {
      description:
        "modules.koltseg",
      text: "modules.koltseg.title",
    },
    {
      description:
      "modules.dontes",
      text: "modules.dontes.title",
    },
    {
      description:
      "modules.ugyfelszolgalat",
      text: "modules.ugyfelszolgalat.title",
    },
  ];

  constructor(
    private simpleModalService: SimpleModalService,
    private plbrowserService: PlbrowserService
  ) {}

  openModal() {
    this.simpleModalService.addModal(
      ModalComponent,
      { title: "Kapcsolat" },
      {
        closeOnClickOutside: true,
        closeOnEscape: true,
        // autoFocus: true
      }
    );
  }

  onInViewportChange(inViewport: boolean) {
    if (this.plbrowserService.isBrowser) {
      // if (inViewport) {
      //   document
      //     .querySelector('[fragment="megoldasunk-elonyei"]')
      //     .classList.add("active");
      // } else {
      //   document
      //     .querySelector('[fragment="megoldasunk-elonyei"]')
      //     .classList.remove("active");
      // }
    }
  }
  private removeAllElementsClass(elements, className) {
    for (const iterator of elements) {
      iterator.classList.remove(className);
    }
    /* for (let j = 0; j < elements.length; j++) {
      const moreText = elements[j];
      moreText.classList.remove(className);
    } */
  }
  bubbleMethod() {
    const bubbles = document.querySelectorAll(".bubble");
    // userAgent
    const userAgent = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        userAgent
      )
    ) {
      const bubbles = document.querySelectorAll(".bubble");
      const moreTexts = document.querySelectorAll(".bubble .more-text");
      for (let index = 0; index < bubbles.length; index++) {
        const bubble = bubbles[index];
        bubble.addEventListener("click", () => {
          let hasOrange = false;
          if (bubble.classList.contains("orange")) {
            hasOrange = true;
          }
          this.removeAllElementsClass(bubbles, "orange");
          if (hasOrange) {
            bubble.classList.remove("orange");
          } else {
            bubble.classList.add("orange");
          }
          const currentMoreText = bubble.querySelector(".more-text");
          let hasOpenClass = false;
          if (currentMoreText.classList.contains("open")) {
            hasOpenClass = true;
          }
          this.removeAllElementsClass(moreTexts, "open");
          if (hasOpenClass) {
            currentMoreText.classList.remove("open");
          } else {
            currentMoreText.classList.add("open");
          }
        });
      }
    } else {
      for (let index = 0; index < bubbles.length; index++) {
        const bubble = bubbles[index];
        const currentImgDiv = bubble.querySelector(".img-div");

        currentImgDiv.addEventListener("mouseenter", () => {
          const currentMoreText = bubble.querySelector(".more-text");

          if (!currentMoreText.classList.contains("open")) {
            currentMoreText.classList.add("open");
            currentMoreText.classList.add("fade-animation");
          }
        });

        bubble.addEventListener("mouseout", () => {
          const currentMoreText = bubble.querySelector(".more-text");

          if (currentMoreText.classList.contains("open")) {
            currentMoreText.classList.remove("open");
            currentMoreText.classList.remove("fade-animation");
          }
        });
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.plbrowserService.isBrowser) {
      this.bubbleMethod();
    }
  }
}
